<template>
  <div class="menus">
    <div id="">
      <el-form
        ref="queryForm"
        v-hasPermi="['an:er:list']"
        :inline="true"
        label-width="60px"
        style="width: 100%; text-align: left"
      >
        <el-form-item label="分类" prop="roleName">
          <el-select
            size="small"
            v-model="queryParams.types"
            placeholder="请选择"
          >
            <el-option label="测试" value="0"> </el-option>
            <el-option label="练习" value="1"> </el-option>
            <el-option label="考试" value="2"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="roleKey">
          <el-input
            v-model="queryParams.title"
            placeholder="请输入标题"
            clearable
            size="small"
            style="width: 200px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="" prop="status">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            v-hasPermi="['an:er:list']"
            >搜索</el-button
          >
          <el-button
            icon="el-icon-refresh"
            size="mini"
            @click="resetQuery"
            v-hasPermi="['an:er:list']"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <!-- <div class="tian">
      <el-button
        type="primary"
        plain
        icon="el-icon-plus"
        size="mini"
        @click="addition()"
        style="float: left"
        v-hasPermi="['an:er:add']"
        >添加</el-button
      >
    </div> -->

    <el-table
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#F8F8F9', color: '#515A6E' }"
    >
      <el-table-column prop="title" label="标题"> </el-table-column>
      <el-table-column prop="types" label="类型">
        <template slot-scope="scope">
          <span v-show="scope.row.types == 0">测试</span>
          <span v-show="scope.row.types == 1">练习</span>
          <span v-show="scope.row.types == 2">考试</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态"> </el-table-column>
      <el-table-column prop="starttime" label="开始时间"> </el-table-column>
      <el-table-column prop="endtime" label="结束时间"> </el-table-column>

      <el-table-column prop="count" label="考试量">
        <template slot-scope="scope">
          <el-button size="small" @click="skip(scope.$index, scope.row)">{{
            scope.row.count
          }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEdit(scope.$index, scope.row)"
            v-hasPermi="['an:er:eqit']"
            >编辑</el-button
          >
          <!-- <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.$index, scope.row)"
            v-hasPermi="['an:er:delete']"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="info()"
    />
  </div>
</template>



<script>
// import Pagination from "@/components/Pagination";
import request from "../../../utils/request.js";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  components: { Treeselect },
  data() {
    return {
      total: 0,
      formLabelWidth: "120px",
      tableData: [],
      id: 0,
      dateRange: [],
      queryParams: {
        types: "", //搜索名称
        title: "", //搜索电话
        pageSize: 10, //分页默认一页显示15条数据
        total: 0, //共多少条数据从接口获取
        pageNum: 1, //分页默认显示第1页数据
      },
      clickMsg: "1", //搜索数据分页和查询全部数据分页开关 1 查询所有数据  2 搜索数据  3 角色查询
      nstitution: [],
    };
  },
  created() {
    this.info();
    // this.getList();
  },
  activated() {
    this.info();
  },
  methods: {
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        children: node.children,
      };
    },
    //获取列表数据
    info() {
      this.clickMsg = "1"; //搜索数据分页和查询全部数据分页开关 false 查询所有数据分页

      let dats = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        realname: this.queryParams.realname,
        types: this.queryParams.types,
        title: this.queryParams.title,
      };
      return request({
        url: "reply/queryReply",
        method: "get",
        params: dats,
      }).then((res) => {
        if (res.status == 200) {
          // console.log(res)
          this.tableData = res.data.data;
          // this.queryParams.pageCount = res.data.pageBean.pageCount; //接口获取到的共多少页
          this.total = res.data.pageBean.count; //接口获取到的共多少条
        }
      });
    },

    //添加
    addition() {
      this.$router.push({ path: "/Home/unpdnew" });
    },

    //搜索
    handleQuery() {
      this.info();
      this.queryParams.createTime = this.dateRange[0];
      this.queryParams.endTime = this.dateRange[1];
    },
    //搜索重置
    resetQuery() {
      this.queryParams.types = "";
      this.queryParams.title = "";
      this.info();
    },
    skip(val, row) {
      //  console.log(row)
      //  console.log(val)
      this.$router.push({ path: "/Home/tentof", query: { id: row.id } });
    },
    //编辑
    handleEdit(val, row) {
      //  console.log(row.id)
      this.$router.push({ path: "/Home/unpdnew", query: { id: row.id } });
    },

    //删除
    handleDelete(index, row) {
      // console.log( row);
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            replyId: row.id,
          };
          return request({
            url: "reply/deleteReplyById",
            method: "delete",
            data: data,
          }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.info();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.tian {
  width: 100%;
  height: 60px;
}
.el-dialog {
  width: 500px !important;
}
.el-form {
  height: auto;
}
.el-pagination {
  text-align: right;
}
</style>
 
